<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <FilteringPanelWrapper
              :model="model"
              :filters="filters"
              @get-data="onFilteringPanelGetData"
            ></FilteringPanelWrapper>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TableWrapper
              :ref="model.name"
              :model="model"
              actions-mode="inline"
              :columns="tableColumns"
              :read-only="false"
              :filter-by-column="false"
              @inserted="onTableInsert"
              @updated="onTableChange"
            >
            </TableWrapper>
          </b-col>
        </b-row>
        <b-row style="margin-bottom: 10em;"> </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import models from '@/models'
import FilteringPanelWrapper from '@/components/DataView/FilteringPanelWrapper'
import TableWrapper from '@/components/DataView/TableWrapper'

export default {
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    mode: {
      type: Number,
      default: 0
    },
    active: {
      type: [Number, Boolean],
      default: 0
    }
  },
  name: 'BonusPayments',
  components: { FilteringPanelWrapper, TableWrapper },
  data: function () {
    return {
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      model: models.userManagement.bonusPayments,
      filters: ['User Name', 'Bonus Date'],
      tableColumns: [
        'ID',
        'User Name',
        'YTD Amount',
        'Bonus Date',
        'Amount',
        'Description',
        'Created By',
        'Created',
        'Updated By',
        'Updated'
      ],
      data: {}
    }
  },
  created () {
    console.log('bonusPayments.created')
  },
  async mounted () {
    console.log('bonusPayments.mounted')
  },
  methods: {
    onTableInsert () {},
    onTableChange () {},
    async onFilteringPanelGetData (payload) {
      console.log('onFilteringPanelGetData', payload)
      await this.$refs[this.model.name].getData(payload)
    }
  },
  watch: {}
}
</script>

<style scoped>
/*
::v-deep .flex-row {
  width: 100%;
}*/
::v-deep .w-short {
  width: 30em !important;
}

::v-deep .VueTables__table {
  overflow: visible !important;
}
::v-deep .table-responsive {
  overflow: visible !important;
}
</style>
