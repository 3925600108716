var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": _vm.model.name,
      "load-dictionaries": _vm.loadDictionaries,
      "show-labels": true,
      "hide-custom-filters": false,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }